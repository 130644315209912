<template>
  <div class="card">
    <h3 class="card-title">Rectificar DDJJ para percepción de asignaciones familiares Prenatal / Maternidad / Maternidad
      down</h3>
    <form v-if="loaded" @submit.prevent="save">
      <div class="cols2 my-2">
        <div class="form-group">
          <label class="form-label">Seleccione el tipo de percepción *</label>
          <select
              id="tipo_percepcion"
              class="form-control"
              v-model="percepcion.tipo_percepcion"
              :class="{'is-invalid': enviado && $v.percepcion.tipo_percepcion.$invalid}"
          >
            <option :value="null">Seleccione una opción</option>
            <option :value="TipoPercepcionConstants.PRENATAL">{{ TipoPercepcionConstants.PRENATAL }}</option>
            <option :value="TipoPercepcionConstants.MATERNIDAD">{{ TipoPercepcionConstants.MATERNIDAD }}</option>
            <option :value="TipoPercepcionConstants.MATERNIDAD_DOWN">{{ TipoPercepcionConstants.MATERNIDAD_DOWN }}
            </option>
          </select>
          <small class="form-message text-danger" v-if="enviado && $v.percepcion.tipo_percepcion.$invalid">
            Éste campo es obligatorio
          </small>
        </div>
      </div>

      <template v-if="percepcion.tipo_percepcion === TipoPercepcionConstants.PRENATAL">
        <h4>Prenatal</h4>
        <div class="cols2">
          <div class="form-group">
            <label class="form-label">Tipo de novedad *</label>
            <select
                id="tipo_novedad"
                class="form-control"
                :class="{'is-invalid': enviado && $v.percepcion.tipo_novedad.$invalid}"
                v-model="percepcion.tipo_novedad"
            >
              <option :value="null">Seleccione una opción</option>
              <option :value="TipoNovedadConstants.ALTA">{{ TipoNovedadConstants.ALTA }}</option>
              <option :value="TipoNovedadConstants.BAJA">{{ TipoNovedadConstants.BAJA }}</option>
              <option :value="TipoNovedadConstants.MODIFICACION">{{ TipoNovedadConstants.MODIFICACION }}</option>
            </select>
            <small class="form-message text-danger" v-if="enviado && $v.percepcion.tipo_novedad.$invalid">
              Éste campo es obligatorio
            </small>
          </div>
        </div>
      </template>
      <template v-if="percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD">
        <h4>Licencia por maternidad</h4>
        <div class="cols2">
          <div class="form-group">
            <label class="form-label">Tipo de novedad *</label>
            <select
                id="tipo_novedad"
                class="form-control"
                v-model="percepcion.tipo_novedad"
                :class="{'is-invalid': enviado && $v.percepcion.tipo_novedad.$invalid}"
            >
              <option :value="null">Seleccione una opción</option>
              <option :value="TipoNovedadConstants.ALTA">{{ TipoNovedadConstants.ALTA }}</option>
              <option :value="TipoNovedadConstants.BAJA">{{ TipoNovedadConstants.BAJA }}</option>
            </select>
            <small class="form-message text-danger" v-if="enviado && $v.percepcion.tipo_novedad.$invalid">
              Éste campo es obligatorio
            </small>
          </div>
          <!--          <div class="form-group">-->
          <!--            <label class="form-label">Tipo de licencia *</label>-->
          <!--            <select-->
          <!--              id="tipo_novedad"-->
          <!--              class="form-control"-->
          <!--              v-model="percepcion.maternidad_tipo_licencia"-->
          <!--              :class="{'is-invalid': enviado && $v.percepcion.maternidad_tipo_licencia.$invalid}"-->
          <!--            >-->
          <!--              <option :value="null">Seleccione una opción</option>-->
          <!--              <option :value="TipoLicenciaMaternidadConstants.LIC_45_45">45 días Pre Parto y 45 días Post Parto</option>-->
          <!--              <option :value="TipoLicenciaMaternidadConstants.LIC_30_60">30 días Pre Parto y 60 días Post Parto</option>-->
          <!--            </select>-->
          <!--            <small class="form-message text-danger" v-if="enviado && $v.percepcion.maternidad_tipo_licencia.$invalid">-->
          <!--              Éste campo es obligatorio-->
          <!--            </small>-->
          <!--          </div>-->
          <pd-input
              v-model="percepcion.maternidad_fecha_notificacion"
              id="fecha_notificacion"
              label="Fecha de notificacion al empleador de la declaración de opción de licencia *"
              type="date"
              :required="enviado && $v.percepcion.maternidad_fecha_notificacion.$invalid"
          />
          <pd-input
              v-model="percepcion.maternidad_dias_previos_fpp"
              id="dias_previos_fpp"
              label="Cantidad de días corridos antes de la F.P.P. (Mínimo 10 días preparto - Máximo 45 días preparto)"
              type="number"
              min="10"
              max="45"
              :class-input="{'form-control': true,'is-invalid': percepcion.maternidad_dias_previos_fpp && (percepcion.maternidad_dias_previos_fpp < 10 || percepcion.maternidad_dias_previos_fpp > 45)}"
              inputmode="numeric"
              :required="enviado && $v.percepcion.maternidad_dias_previos_fpp.$invalid"
          >
            <template #validation>
              <small
                  class="form-message text-danger"
                  v-if="percepcion.maternidad_dias_previos_fpp && (percepcion.maternidad_dias_previos_fpp < 10 || percepcion.maternidad_dias_previos_fpp > 45)"
              >
                El mínimo es 10 y el máximo 45
              </small>
            </template>
          </pd-input>
        </div>
        <div class="cols2">
          <pd-input
              v-model="percepcion.maternidad_fecha_inicio"
              id="fecha_inicio"
              label="Fecha de inicio de licencia por maternidad *"
              type="date"
              :required="enviado && $v.percepcion.maternidad_fecha_inicio.$invalid"
          />
          <pd-input
              v-model="percepcion.maternidad_fecha_fin"
              id="fecha_fin"
              label="Fecha de fin de licencia por maternidad *"
              type="date"
              disabled
              :required="enviado && $v.percepcion.maternidad_fecha_fin.$invalid"
          />
          <pd-input
              v-model="fechaReincorporacion"
              id="fecha_reincorporacion"
              label="Fecha de reincorporacion"
              type="date"
              readonly
          />

        </div>
      </template>
      <template v-if="
        percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD ||
        percepcion.tipo_percepcion === TipoPercepcionConstants.PRENATAL
       "
      >
        <h4>Datos de certificado médico</h4>
        <div class="form-group d-flex align-end">
          <label class="d-flex left btn-flat-dark text-small form-control"
                 :class="{'is-invalid': enviado && $v.percepcion.certificado_medico_certificado.$invalid}"
                 style="padding: .5rem"
                 for="certificado_medico">
            <icon feather="edit" style="height:1rem"/>
            <span v-if="percepcion.certificado_medico_certificado" class="px-1"
                  style="margin-top: .25rem">
                    {{ percepcion.certificado_medico_certificado_original_name }}
              </span>
            <span v-else class="px-1"
                  style="margin-top: .25rem">Adjunte certificado *</span>
            <input
                type="file"
                id="certificado_medico"
                @change="changeCertificadoMedico"
                style="display: none"
            />
          </label>
          <small class="form-message text-danger"
                 v-if="enviado && $v.percepcion.certificado_medico_certificado.$invalid"
          >Éste campo es obligatorio.</small
          >
        </div>
      </template>


      <template v-if="percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN">
        <h4>Maternidad down</h4>
        <div class="cols2">
          <div class="form-group">
            <label class="form-label">Tipo de novedad *</label>
            <select
                id="tipo_novedad"
                class="form-control"
                :class="{'is-invalid': enviado && $v.percepcion.tipo_novedad.$invalid}"
                v-model="percepcion.tipo_novedad"
            >
              <option :value="null">Seleccione una opción</option>
              <option :value="TipoNovedadConstants.ALTA">{{ TipoNovedadConstants.ALTA }}</option>
              <option :value="TipoNovedadConstants.BAJA">{{ TipoNovedadConstants.BAJA }}</option>
            </select>
            <small class="form-message text-danger" v-if="enviado && $v.percepcion.tipo_novedad.$invalid">
              Éste campo es obligatorio
            </small>
          </div>

          <pd-input
              v-model="percepcion.maternidad_down_fecha_fin_licencia"
              id="maternidad_down_fecha_fin_licencia"
              label="Fecha de fin de licencia por maternidad *"
              type="date"
              placeholder="date"
              :required="enviado && $v.percepcion.maternidad_down_fecha_fin_licencia.$invalid"
          />

          <pd-input
              v-model="percepcion.maternidad_down_cuil_hijo"
              id="maternidad_down_cuil_hijo"
              label="Cuil hijo *"
              placeholder="Cuil hijo"
              :required="enviado && $v.percepcion.maternidad_down_cuil_hijo.$invalid"
          />

          <pd-input
              v-model="percepcion.maternidad_down_dni_hijo"
              id="maternidad_dni_cuil_hijo"
              label="DNI hijo *"
              placeholder="DNI hijo"
              :required="enviado && $v.percepcion.maternidad_down_dni_hijo.$invalid"
          />

          <pd-input
              v-model="percepcion.maternidad_down_nombre_hijo"
              id="maternidad_down_nombre_hijo"
              label="Apellido y nombre completo de hijo *"
              placeholder="Apellido y nombre completo de hijo"
              :required="enviado && $v.percepcion.maternidad_down_nombre_hijo.$invalid"
          />

          <div class="form-group">
            <label class="form-label">Tipo de partida *</label>
            <select
                id="tipo_partida"
                class="form-control"
                :class="{'is-invalid': enviado && $v.percepcion.maternidad_down_tipo_partida.$invalid}"
                v-model="percepcion.maternidad_down_tipo_partida"
            >
              <option :value="null">Seleccione una opción</option>
              <option :value="TipoPartidaConstants.NACIMIENTO">{{ TipoPartidaConstants.NACIMIENTO }}</option>
              <option :value="TipoPartidaConstants.DEFUNCION">{{ TipoPartidaConstants.DEFUNCION }}</option>
            </select>
            <small class="form-message text-danger"
                   v-if="enviado && $v.percepcion.maternidad_down_tipo_partida.$invalid">
              Éste campo es obligatorio
            </small>
          </div>

          <pd-input
              v-model="percepcion.maternidad_down_numero_partida"
              id="maternidad_down_numero_partida"
              label="N° de Acta/Partida/Certificado *"
              placeholder="N° de Acta/Partida/Certificado"
              :required="enviado && $v.percepcion.maternidad_down_numero_partida.$invalid"
          />

          <pd-input
              v-model="percepcion.maternidad_down_numero_tomo"
              id="maternidad_down_numero_tomo"
              label="N° de tomo *"
              placeholder="N° de tomo"
              :required="enviado && $v.percepcion.maternidad_down_numero_tomo.$invalid"
          />

          <pd-input
              v-model="percepcion.maternidad_down_numero_folio"
              id="maternidad_down_numero_folio"
              label="N° de folio *"
              placeholder="N° de folio"
              :required="enviado && $v.percepcion.maternidad_down_numero_folio.$invalid"
          />

        </div>
        <h4>Datos de certificado médico</h4>

        <div class="form-group d-flex align-end">
          <label class="d-flex left btn-flat-dark text-small form-control"
                 :class="{'is-invalid': enviado && $v.percepcion.certificado_medico_certificado.$invalid}"
                 style="padding: .5rem"
                 for="certificado_medico">
            <icon feather="edit" style="height:1rem"/>
            <span v-if="percepcion.certificado_medico_certificado" class="px-1"
                  style="margin-top: .25rem">
                    {{ percepcion.certificado_medico_certificado_original_name }}
              </span>
            <span v-else class="px-1"
                  style="margin-top: .25rem">Adjunte certificado *</span>
            <input
                type="file"
                id="certificado_medico"
                @change="changeCertificadoMedico"
                style="display: none"
            />
          </label>
          <small class="form-message text-danger"
                 v-if="enviado && $v.percepcion.certificado_medico_certificado.$invalid"
          >Éste campo es obligatorio.</small
          >
        </div>
        <!--        <div>-->
        <!--          <pd-input-->
        <!--              v-model="percepcion.certificado_medico_certificado"-->
        <!--              id="certificado_medico_certificado"-->
        <!--              label="Adjunte certificado *"-->
        <!--              type="file"-->
        <!--              :required="enviado && $v.percepcion.certificado_medico_certificado.$invalid"-->
        <!--          />-->
        <!--        </div>-->
      </template>

      <div class="mt-2">
        <button class="btn btn-right btn-primary">Guardar</button>
      </div>
    </form>
  </div>
</template>
<script>
import {
  percepcionMaternidadForm
} from "@/modules/percepcionesAsignacionFamiliar/services/percepcionMaternidadForm";
import {mapState} from "vuex";
import Suggest from "@/components/elements/Suggest.vue";
import {TipoParentezcoConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoParentezco";
import {TipoPercepcionConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoPercepcion";
import {TipoNovedadConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoNovedad";
import {required, requiredIf} from "vuelidate/lib/validators";
import {TipoLicenciaMaternidadConstants} from "../constants/tipoLicenciaMaternidad";
import {TipoFechaPartoConstants} from "../constants/tipoFechaParto";
import {TipoPartidaConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoPartida";
import Swal from "sweetalert2";
import {percepcionMaternidadApi} from "@/modules/percepcionesAsignacionFamiliar/services/percepcionMaternidadApi";
import {DateTime} from "luxon";
import {asignacionesFamiliaresApi} from "@/modules/percepcionesAsignacionFamiliar/services/asignacionesFamiliaresApi";

export default {
  name: 'CrearPercepcion',
  components: {Suggest},
  data: () => {
    return {
      percepcion: percepcionMaternidadForm(null),
      enviado: false,
      asignacionFamiliar: null,
      loaded: false
    }
  },
  watch: {
    'percepcion.maternidad_fecha_inicio'() {
      if (this.percepcion.maternidad_fecha_inicio) {
        let desde = this.validateDate(this.percepcion.maternidad_fecha_inicio) ?
            DateTime.fromFormat(this.percepcion.maternidad_fecha_inicio, 'yyyy-LL-dd') :
            null;
        const hasta = desde.plus({days: 90})
        this.percepcion.maternidad_fecha_fin = hasta.toFormat('yyyy-LL-dd')
      }
    },
  },
  validations: {
    percepcion: {
      tipo_percepcion: {required},
      tipo_novedad: {required},
      maternidad_dias_previos_fpp: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD
        })
      },
      maternidad_fecha_notificacion: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD
        })
      },
      maternidad_fecha_inicio: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD
        })
      },
      maternidad_fecha_fin: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD
        })
      },
      certificado_medico_certificado: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD ||
              percepcion.tipo_percepcion === TipoPercepcionConstants.PRENATAL
        })
      },
      maternidad_down_fecha_fin_licencia: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN
        })
      },
      maternidad_down_cuil_hijo: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN
        })
      },
      maternidad_down_dni_hijo: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN
        })
      },
      maternidad_down_nombre_hijo: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN
        })
      },
      maternidad_down_tipo_partida: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN
        })
      },
      maternidad_down_numero_partida: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN
        })
      },
      maternidad_down_numero_tomo: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN
        })
      },
      maternidad_down_numero_folio: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN
        })
      },
    }
  },
  computed: {
    TipoPartidaConstants() {
      return TipoPartidaConstants
    },
    TipoFechaPartoConstants() {
      return TipoFechaPartoConstants
    },
    TipoLicenciaMaternidadConstants() {
      return TipoLicenciaMaternidadConstants
    },
    TipoNovedadConstants() {
      return TipoNovedadConstants
    },
    TipoPercepcionConstants() {
      return TipoPercepcionConstants
    },
    TipoParentezcoConstants() {
      return TipoParentezcoConstants
    },
    fechaReincorporacion() {
      if (this.percepcion.maternidad_fecha_fin) {
        return DateTime.fromFormat(this.percepcion.maternidad_fecha_fin, 'yyyy-LL-dd').plus({days: 1}).toFormat('yyyy-LL-dd')
      }
      return null
    },
    ...mapState(["user"]),
  },
  mounted() {
    asignacionesFamiliaresApi
        .show(this.$route.params.id)
        .then(asignacionFamiliar => {
          this.asignacionFamiliar = asignacionFamiliar;
          this.percepcion = percepcionMaternidadForm(this.asignacionFamiliar)
          this.loaded = true
        })
  },
  methods: {
    save() {
      this.enviado = true;
      this.$v.$touch();
      if (this.$v.$invalid || this.percepcion.maternidad_dias_previos_fpp && (this.percepcion.maternidad_dias_previos_fpp < 10 || this.percepcion.maternidad_dias_previos_fpp > 45)) {
        return false;
      }

      const formData = new FormData();
      formData.append('year', this.percepcion.year)
      formData.append('tipo_percepcion', this.percepcion.tipo_percepcion)
      formData.append('tipo_novedad', this.percepcion.tipo_novedad)
      formData.append('maternidad_fecha_notificacion', this.percepcion.maternidad_fecha_notificacion)
      formData.append('maternidad_dias_previos_fpp', this.percepcion.maternidad_dias_previos_fpp)
      formData.append('maternidad_fecha_inicio', this.percepcion.maternidad_fecha_inicio)
      formData.append('maternidad_fecha_fin', this.percepcion.maternidad_fecha_fin)
      formData.append('maternidad_down_fecha_fin_licencia', this.percepcion.maternidad_down_fecha_fin_licencia)
      formData.append('maternidad_down_cuil_hijo', this.percepcion.maternidad_down_cuil_hijo)
      formData.append('maternidad_down_dni_hijo', this.percepcion.maternidad_down_dni_hijo)
      formData.append('maternidad_down_nombre_hijo', this.percepcion.maternidad_down_nombre_hijo)
      formData.append('maternidad_down_tipo_partida', this.percepcion.maternidad_down_tipo_partida)
      formData.append('maternidad_down_numero_partida', this.percepcion.maternidad_down_numero_partida)
      formData.append('maternidad_down_numero_tomo', this.percepcion.maternidad_down_numero_tomo)
      formData.append('maternidad_down_numero_folio', this.percepcion.maternidad_down_numero_folio)
      formData.append('certificado_medico_certificado',this.percepcion.certificado_medico_certificado)
      percepcionMaternidadApi.update(this.asignacionFamiliar.percepcion_maternidad.id, formData).then((response) => {
        if (response.success) {
          Swal.fire("Éxito", "Se guardó correcamente", "success");
          this.$router.push({name: "ListarPercepciones"});
        } else {
          Swal.fire("Error", "No se pudo guardar", "error");
        }
      })
          .catch((error) => {
            Swal.fire(
                "Error",
                `<p>Error de servidor</p><p>${error}</p>`,
                "error"
            );
          });
    },
    validateDate(date) {
      let controlDate = new Date("01-01-2000"); //dd-mm-YYYY
      if (date) {
        let newDate = new Date(date) ?? null; //dd-mm-YYYY
        if (newDate > controlDate) {
          return true;
        }
      }
      return false;
    },
    changeCertificadoMedico(e) {
      this.percepcion.certificado_medico_certificado = e.target.files[0]
      this.percepcion.certificado_medico_certificado_original_name = e.target.files[0].name
      console.log(this.percepcion)
    },

  }
}
</script>

<style scoped>

</style>
